import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ data, isLoadingRedux, currentUserId }) => {
  const chartRef = useRef(null);

  // Cleanup function to destroy chart instance
  useEffect(() => {
    const chart = chartRef.current;
    
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  if (!data || !data.datasets) {
    return null;
  }

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#FFFFFF',
          font: {
            size: 12
          }
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          stepSize: 1,
          color: '#FFFFFF',
          font: {
            size: 12
          },
          callback: function(value) {
            return Math.floor(value);
          }
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          color: (context) => {
            const userId = data.labelData[context.index]?.id;
            return userId === currentUserId ? '#FFC107' : '#FFFFFF';
          },
          font: {
            size: 12,
            weight: (context) => {
              const userId = data.labelData[context.index]?.id;
              return userId === currentUserId ? 'bold' : 'normal';
            }
          }
        }
      }
    }
  };

  return (
    <div className="barChartContainer" style={{ height: '360px' }}>
      <Bar 
        ref={chartRef}
        data={data} 
        options={options}
      />
    </div>
  );
};

export default BarChart;
