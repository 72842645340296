import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import './LocationModal.css';

const libraries = ['places'];

const LocationModal = ({ isOpen, onClose, onLocationSelect }) => {
  const locationInputRef = useRef(null);
  const autocompleteRef = useRef(null);
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (isOpen && isLoaded && locationInputRef.current) {
      const google = window.google;
      autocompleteRef.current = new google.maps.places.Autocomplete(
        locationInputRef.current,
        {
          types: ['establishment'],
          fields: [
            'formatted_address',
            'geometry',
            'name',
            'place_id',
            'types',
            'address_components'
          ],
          componentRestrictions: { country: ['us', 'th'] },
          disableDefaultUI: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          map: null
        }
      );

      google.maps.event.addListenerOnce(autocompleteRef.current, 'place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        if (place) {
          onLocationSelect({
            name: place.name || place.formatted_address,
            address: place.formatted_address,
            placeId: place.place_id,
            lat: place.geometry?.location?.lat(),
            lng: place.geometry?.location?.lng(),
            types: place.types
          });
        }
      });

      return () => {
        if (autocompleteRef.current) {
          google.maps.event.clearInstanceListeners(autocompleteRef.current);
        }
      };
    }
  }, [isOpen, isLoaded, onLocationSelect]);

  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay" onClick={onClose}>
      <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
        <h3>Add Location</h3>
        <input
          ref={locationInputRef}
          type="text"
          placeholder="Search for a location..."
          className="location-input"
          autoFocus
        />
        <div className="custom-modal-actions">
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationModal; 
