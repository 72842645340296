import React, { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs, onSnapshot, addDoc, serverTimestamp, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../firebase/config';
import { useAuth } from '../../context/AuthContext';
import './Feed.css';
import { useLoadScript } from '@react-google-maps/api';
import LocationModal from './LocationModal';

const MAX_IMAGE_WIDTH = 1200; // Maximum width for images
const JPEG_QUALITY = 0.7; // Image quality (0.7 = 70% quality)
const POSTS_PER_PAGE = 5; // Changed from 15 to 3

// Define libraries array outside component to prevent reloading
const libraries = ['places'];

const Feed = ({ selectedGroup }) => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [image, setImage] = useState(null);
  const [location, setLocation] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const { user: authUser } = useAuth();
  const lastPostRef = useRef(null);
  const feedContainerRef = useRef(null);
  const locationInputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const MAX_CHARS = 155;

  const handleScroll = () => {
    if (!feedContainerRef.current) return;

    const { scrollHeight, scrollTop, clientHeight } = feedContainerRef.current;
    const scrolledToBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;

    if (scrolledToBottom && !isLoadingMore && hasMore) {
      loadMorePosts();
    }
  };

  useEffect(() => {
    if (!selectedGroup?.id) return;

    // Reset state when group changes
    setPosts([]);
    setHasMore(true);
    lastPostRef.current = null;

    const q = query(
      collection(db, `groups/${selectedGroup.id}/posts`),
      orderBy('createdAt', 'desc'),
      limit(POSTS_PER_PAGE)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postsData);
      if (snapshot.docs.length > 0) {
        lastPostRef.current = snapshot.docs[snapshot.docs.length - 1];
      }
      setHasMore(snapshot.docs.length === POSTS_PER_PAGE);
    });

    return () => unsubscribe();
  }, [selectedGroup]);

  useEffect(() => {
    if (showLocationModal && isLoaded && locationInputRef.current) {
      const google = window.google;
      autocompleteRef.current = new google.maps.places.Autocomplete(
        locationInputRef.current,
        {
          types: ['establishment'],
          fields: [
            'formatted_address',
            'geometry',
            'name',
            'place_id',
            'types',
            'address_components'
          ],
          disableDefaultUI: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false
        }
      );

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        if (place) {
          setLocation({
            name: place.name || place.formatted_address,
            address: place.formatted_address,
            placeId: place.place_id,
            lat: place.geometry?.location?.lat(),
            lng: place.geometry?.location?.lng(),
            types: place.types
          });
          setShowLocationModal(false); // Close modal after selection
        }
      });

      return () => {
        if (autocompleteRef.current) {
          google.maps.event.clearInstanceListeners(autocompleteRef.current);
        }
      };
    }
  }, [showLocationModal, isLoaded]);

  const loadMorePosts = async () => {
    if (!hasMore || isLoadingMore || !lastPostRef.current) return;

    try {
      setIsLoadingMore(true);
      const q = query(
        collection(db, `groups/${selectedGroup.id}/posts`),
        orderBy('createdAt', 'desc'),
        startAfter(lastPostRef.current),
        limit(POSTS_PER_PAGE)
      );

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        setHasMore(false);
        return;
      }

      const newPosts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setPosts(prevPosts => [...prevPosts, ...newPosts]);
      lastPostRef.current = snapshot.docs[snapshot.docs.length - 1];
      setHasMore(snapshot.docs.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error('Error loading more posts:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const optimizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions if image is too wide
          if (width > MAX_IMAGE_WIDTH) {
            height = Math.round((height * MAX_IMAGE_WIDTH) / width);
            width = MAX_IMAGE_WIDTH;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Convert to blob
          canvas.toBlob(
            (blob) => {
              if (blob) {
                // Create a new file from the blob
                const optimizedFile = new File([blob], file.name, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                });
                resolve(optimizedFile);
              } else {
                reject(new Error('Failed to optimize image'));
              }
            },
            'image/jpeg',
            JPEG_QUALITY
          );
        };
        img.onerror = () => reject(new Error('Failed to load image'));
      };
      reader.onerror = () => reject(new Error('Failed to read file'));
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const optimizedImage = await optimizeImage(file);
        setImage(optimizedImage);
      } catch (error) {
        console.error('Error optimizing image:', error);
        alert('Failed to process image. Please try again with a different image.');
        e.target.value = ''; // Reset the input
      }
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= MAX_CHARS) {
      setNewPost(text);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ((!newPost && !image) || !selectedGroup?.id || isSubmitting || !authUser?.uid) return;

    try {
      setIsSubmitting(true);
      let imageUrl = null;

      if (image) {
        const timestamp = Date.now();
        const fileName = `${timestamp}_${image.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
        const storageRef = ref(storage, `groups/${selectedGroup.id}/posts/${fileName}`);
        
        try {
          const uploadResult = await uploadBytes(storageRef, image);
          imageUrl = await getDownloadURL(uploadResult.ref);
        } catch (uploadError) {
          console.error('Error uploading image:', uploadError);
          alert('Failed to upload image. Please try again with a smaller image or different file.');
          setIsSubmitting(false);
          return;
        }
      }

      const postData = {
        text: newPost,
        imageUrl,
        location: location ? {
          name: location.name,
          address: location.address,
          placeId: location.placeId,
          coordinates: {
            lat: location.lat,
            lng: location.lng
          }
        } : null,
        createdAt: serverTimestamp(),
        userId: authUser.uid,
        userName: authUser.displayName || authUser.first_name || 'Anonymous',
        userPhotoURL: authUser.photoURL || authUser.photo || '/default-avatar.png'
      };

      await addDoc(collection(db, `groups/${selectedGroup.id}/posts`), postData);

      setNewPost('');
      setImage(null);
      setLocation(null);
      setShowLocationModal(false);
      if (document.getElementById('image-upload')) {
        document.getElementById('image-upload').value = '';
      }
    } catch (error) {
      console.error('Error creating post:', error);
      alert('Failed to create post. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteClick = (post) => {
    setPostToDelete(post);
    setShowDeleteModal(true);
  };

  const handleDeletePost = async (post) => {
    try {
      // Delete image if exists
      if (post.imageUrl) {
        const imageRef = ref(storage, post.imageUrl);
        await deleteObject(imageRef);
      }
      
      // Delete post document
      await deleteDoc(doc(db, `groups/${selectedGroup.id}/posts`, post.id));
      setShowDeleteModal(false);
      setPostToDelete(null);
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('Failed to delete post. Please try again.');
    }
  };

  const canDeletePost = (post) => {
    // Check if user is post owner or group admin
    return post.userId === authUser?.uid || 
           selectedGroup?.users?.some(u => 
             (u.user?.id === authUser?.uid || 
              u.user?._key?.path?.segments?.includes(authUser?.uid)) && 
             u.role === 'admin'
           );
  };

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
    setShowLocationModal(false);
  };

  const handleLocationClick = (location) => {
    if (!location) return;
    
    // Create URLs for different platforms
    const mapsUrl = `comgooglemaps://?q=${encodeURIComponent(location.name)}&center=${location.coordinates.lat},${location.coordinates.lng}&zoom=15`;
    const appleMapsUrl = `maps://?q=${encodeURIComponent(location.name)}&ll=${location.coordinates.lat},${location.coordinates.lng}`;
    const webUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.name)}&query_place_id=${location.placeId}`;

    // Detect iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    if (isIOS) {
      // Try Apple Maps first on iOS
      window.location.href = appleMapsUrl;
      // Fallback to Google Maps app
      setTimeout(() => {
        window.location.href = mapsUrl;
        // Final fallback to web
        setTimeout(() => {
          window.location.href = webUrl;
        }, 500);
      }, 500);
    } else {
      // Try Google Maps app first on Android/others
      window.location.href = mapsUrl;
      // Fallback to web
      setTimeout(() => {
        window.location.href = webUrl;
      }, 500);
    }
  };

  if (!selectedGroup?.id) {
    return <div className="feed-container">Please select a group to view the feed.</div>;
  }

  if (!isLoaded) {
    return <div>Loading places...</div>;
  }

  return (
    <div className="feed-container" ref={feedContainerRef} onScroll={handleScroll}>
      <div className="feed-content">
        <form onSubmit={handleSubmit} className="post-form">
          <textarea
            value={newPost}
            onChange={handleTextChange}
            placeholder="What's on your mind?"
            className="post-input"
            maxLength={MAX_CHARS}
          />
          <div className="post-form-footer">
            <div className="post-attachments">
              <div className="attachment-item">
                <div className="file-input-wrapper">
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="file-input"
                  />
                  <button type="button" className="file-input-button">
                    📷 Photo
                  </button>
                </div>
                {image && (
                  <span className="selected-file" title={image.name}>
                    {image.name}
                  </span>
                )}
              </div>
              <div className="attachment-item">
                <button 
                  type="button" 
                  className="location-button"
                  onClick={() => setShowLocationModal(true)}
                >
                  📍 Location
                </button>
                {location && (
                  <span className="selected-location" title={location.name}>
                    {location.name}
                  </span>
                )}
              </div>
            </div>
            <div className="post-submit">
              <span className="char-count">
                {newPost.length}/{MAX_CHARS}
              </span>
              <button 
                type="submit" 
                disabled={isSubmitting || (!newPost && !image)}
                className="post-button"
              >
                {isSubmitting ? 'Posting...' : 'Post'}
              </button>
            </div>
          </div>
        </form>

        <div className="posts-container">
          {posts.map((post) => (
            <div key={post.id} className="post">
              <div className="post-header">
                <div className="post-header-left">
                  <img 
                    src={post.userPhotoURL || '/default-avatar.png'} 
                    alt={post.userName} 
                    className="user-avatar"
                  />
                  <span className="user-name">{post.userName}</span>
                </div>
                {canDeletePost(post) && (
                  <button 
                    onClick={() => handleDeleteClick(post)}
                    className="delete-post-button"
                    aria-label="Delete post"
                  >
                    ×
                  </button>
                )}
              </div>
              {post.imageUrl && (
                <div className="post-image-container">
                  <img src={post.imageUrl} alt="Post content" className="post-image" />
                </div>
              )}
              {post.text && <p className="post-text">{post.text}</p>}
              {post.location && (
                <button 
                  className="post-location"
                  onClick={() => handleLocationClick(post.location)}
                >
                  📍 {post.location.name}
                </button>
              )}
              <div className="post-timestamp">
                {post.createdAt?.toDate().toLocaleDateString()}
              </div>
            </div>
          ))}
          {isLoadingMore && (
            <div className="loading-more">Loading more posts...</div>
          )}
          {!hasMore && posts.length > 0 && (
            <div className="no-more-posts">No more posts to load</div>
          )}
        </div>
      </div>

      <LocationModal 
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        onLocationSelect={handleLocationSelect}
      />

      {showDeleteModal && postToDelete && (
        <div className="custom-modal-overlay" onClick={() => setShowDeleteModal(false)}>
          <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
            <h3>Delete Post</h3>
            <p>Are you sure you want to delete this post? This action cannot be undone.</p>
            <div className="custom-modal-actions">
              <button 
                onClick={() => handleDeletePost(postToDelete)}
                className="confirm-button"
              >
                Yes, Delete Post
              </button>
              <button 
                onClick={() => {
                  setShowDeleteModal(false);
                  setPostToDelete(null);
                }}
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feed; 
