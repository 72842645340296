import { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import './Home.css';
import BarChart from '../charts/BarChart';
import { doc, updateDoc, collection, onSnapshot, getDoc, addDoc, serverTimestamp, setDoc} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../context/AuthContext';
import GroupModal from '../groups/GroupModal';
import { trackEvent } from '../../firebase/config';
import LoadingWrapper from '../common/LoadingWrapper';
import { fetchUserGroups } from '../../utils/firebaseUtils';
import Feed from '../feed/Feed';

const CHART_COLORS = [
  'rgba(75, 192, 192, 0.8)',  // Teal
  'rgba(255, 99, 132, 0.8)',  // Pink
  'rgba(54, 162, 235, 0.8)',  // Blue
  'rgba(255, 206, 86, 0.8)',  // Yellow
  'rgba(153, 102, 255, 0.8)', // Purple
  'rgba(255, 159, 64, 0.8)'   // Orange
];

const Home = () => {
  const { user: authUser } = useAuth();

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupChartData, setGroupChartData] = useState(null);

  const [value, setValue] = useState('1');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [shouldRefreshGroups, setShouldRefreshGroups] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);
  const [activeTab, setActiveTab] = useState('track');

  const navigate = useNavigate();

  useEffect(() => {
    const initGoogleApi = async () => {
      try {
        await new Promise((resolve, reject) => {
          gapi.load('client:auth2', () => {
            gapi.client.init({
              clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
              scope: "profile"
            })
            .then(resolve)
            .catch(reject);
          });
        });
      } catch (error) {
        console.warn('Google API initialization error:', error);
      }
    };

    initGoogleApi();
  }, []);

  useEffect(() => {
    if (!authUser) {
      setIsLoadingUser(true);
      return;
    }

    setIsLoadingUser(true);
    
    const unsubscribe = onSnapshot(doc(db, 'users', authUser.uid), (snapshot) => {
      if (snapshot.exists()) {
        // Update the authUser with Firestore data
        const userData = {
          ...authUser,
          ...snapshot.data(),
          id: snapshot.id
        };
        // Update the auth context with the combined data
        if (typeof userData.first_name === 'undefined') {
          userData.first_name = authUser.displayName || 'New User';
        }
        setIsLoadingUser(false);
      } else {
        const newUserData = {
          id: authUser.uid,
          email: authUser.email,
          first_name: authUser.displayName || 'New User',
          created_at: serverTimestamp()
        };
        
        setDoc(doc(db, 'users', authUser.uid), newUserData)
          .then(() => {
            setIsLoadingUser(false);
          })
          .catch((error) => {
            console.error('Error creating user document:', error);
            setIsLoadingUser(false);
          });
      }
    }, (error) => {
      console.error('Error fetching user:', error);
      setIsLoadingUser(false);
    });

    return () => unsubscribe();
  }, [authUser]);

  useEffect(() => {
    const fetchGroups = async () => {
      if (!authUser?.uid) {
        return;
      }
      
      setIsLoadingGroups(true);
      try {
        const groupsData = await fetchUserGroups(authUser.uid);
        setGroups(groupsData);
        
        // Get last selected group from localStorage
        const lastSelectedGroupId = localStorage.getItem(`lastSelectedGroup_${authUser.uid}`);
        
        if (groupsData.length > 0) {
          if (lastSelectedGroupId) {
            // Find the group in the fetched groups
            const savedGroup = groupsData.find(g => g.id === lastSelectedGroupId);
            if (savedGroup) {
              setSelectedGroup(savedGroup);
            } else {
              // If saved group not found, default to first group
              setSelectedGroup(groupsData[0]);
            }
          } else {
            // No saved group, default to first group
            setSelectedGroup(groupsData[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        setIsLoadingGroups(false);
      }
    };

    fetchGroups();
  }, [authUser, shouldRefreshGroups]);

  useEffect(() => {
    if (selectedGroup?.items?.length > 0) {
      const itemExists = selectedGroup.items.some(item => 
        (item.id || item.ref?.id) === selectedItem?.value
      );
      
      if (!selectedItem || !itemExists) {
        const firstItem = selectedGroup.items[0];
        setSelectedItem({
          value: firstItem.id || firstItem.ref?.id,
          label: firstItem.name,
          ...firstItem
        });
      }
    }
  }, [selectedGroup?.id, selectedGroup?.items, selectedItem]);

  const handleInputUpdate = async (itemId, value) => {
    if (!selectedGroup || !selectedItem || !value) return;

    setIsUpdating(true);
    try {
      const groupRef = doc(db, 'groups', selectedGroup.id);
      const groupDoc = await getDoc(groupRef);
      const groupData = groupDoc.data();

      const currentUserIndex = groupData.users.findIndex(u => {
        const userId = u.user?.id || 
                      u.user?.path?.split('/').pop() || 
                      u.user;
        return userId === authUser.uid;
      });

      if (currentUserIndex === -1) {
        console.error('User not found in group. Debug info:', {
          groupUsers: groupData.users,
          authUserId: authUser.uid,
          groupId: selectedGroup.id
        });
        throw new Error('User not found in group');
      }

      const currentUser = groupData.users[currentUserIndex];
      const updatedUsers = [...groupData.users];

      if (!currentUser.values) {
        currentUser.values = [];
      }

      const existingValueIndex = currentUser.values.findIndex(v => {
        const valueItemId = v.item?.id || 
                          v.item?.path?.split('/').pop() || 
                          v.item;
        return valueItemId === selectedItem.value;
      });

      if (existingValueIndex !== -1) {
        const currentValue = currentUser.values[existingValueIndex].value;
        updatedUsers[currentUserIndex].values[existingValueIndex].value = 
          currentValue + parseInt(value, 10);
      } else {
        const itemRef = doc(db, 'items', selectedItem.value);
        updatedUsers[currentUserIndex].values.push({
          item: itemRef,
          value: parseInt(value, 10)
        });
      }

      await updateDoc(groupRef, {
        users: updatedUsers,
        updated_at: serverTimestamp()
      });

      setValue('1');
      
    } catch (error) {
      console.error('Update error:', error);
      console.error('Error details:', { 
        selectedGroup, 
        selectedItem, 
        value, 
        authUser,
        userPath: `users/${authUser.uid}`
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const fetchUserNames = async (groupData) => {
    if (!groupData?.users || !groupData?.items) {
      console.log('Missing users or items in group:', groupData);
      return;
    }

    try {
      const userPromises = groupData.users.map(async (userObj) => {
        const userId = userObj.user?.id || userObj.user;
        if (!userId) {
          console.log('Invalid user reference:', userObj);
          return null;
        }

        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        const userValues = groupData.items.map(item => {
          const valueObj = userObj.values?.find(v => {
            const valueItemId = v.item?.id || v.item;
            const itemId = item.id || item.ref?.id;
            return valueItemId === itemId;
          });
          return valueObj?.value || 0;
        });

        return {
          displayName: userData?.first_name || 'User',
          values: userValues,
          userId: userId
        };
      });

      const usersWithNames = (await Promise.all(userPromises)).filter(Boolean);

      const labels = usersWithNames.map(user => ({
        display: user.displayName,
        id: user.userId
      }));

      const chartData = {
        labels: labels.map(label => label.display),
        labelData: labels,
        datasets: groupData.items.map((item, index) => ({
          label: item.name,
          data: usersWithNames.map(user => user.values[index] || 0),
          backgroundColor: CHART_COLORS[index % CHART_COLORS.length],
          borderColor: CHART_COLORS[index % CHART_COLORS.length],
          borderWidth: 1,
          borderRadius: 4,
          barThickness: 20,
          maxBarThickness: 30,
          minBarLength: 2
        }))
      };

      setGroupChartData(chartData);

    } catch (error) {
      console.error('Error in fetchUserNames:', error);
      setGroupChartData(null);
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      fetchUserNames(selectedGroup);
    }
  }, [selectedGroup]);

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
    
    if (!selectedOption) {
      setGroupChartData(null);
      // Clear saved group when none selected
      if (authUser?.uid) {
        localStorage.removeItem(`lastSelectedGroup_${authUser.uid}`);
      }
      return;
    }

    // Save selected group to localStorage
    if (authUser?.uid) {
      localStorage.setItem(`lastSelectedGroup_${authUser.uid}`, selectedOption.id);
    }
  };

  useEffect(() => {
    if (!selectedGroup?.id) return;

    const unsubscribe = onSnapshot(doc(db, 'groups', selectedGroup.id), 
      async (snapshot) => {
        if (snapshot.exists()) {
          const groupData = snapshot.data();
          const updatedGroup = {
            id: selectedGroup.id,
            label: groupData.name,
            created_at: groupData.created_at,
            ...groupData
          };
          
          setSelectedGroup(updatedGroup);
          setGroups(prevGroups => {
            const index = prevGroups.findIndex(g => g.id === selectedGroup.id);
            if (index === -1) return [...prevGroups, updatedGroup];
            const newGroups = [...prevGroups];
            newGroups[index] = updatedGroup;
            return newGroups;
          });

          await fetchUserNames(updatedGroup);
        }
      },
      (error) => {
        console.error("Error listening to group updates:", error);
      }
    );

    return () => unsubscribe();
  }, [selectedGroup?.id]);

  const handleGroupAdded = async (newGroupId) => {
    try {
      const groupDoc = await getDoc(doc(db, 'groups', newGroupId));
      if (groupDoc.exists()) {
        const groupData = groupDoc.data();
        const newGroup = {
          value: newGroupId,
          label: groupData.name,
          created_at: groupData.created_at,
          ...groupData
        };
        
        setGroups(prevGroups => [newGroup, ...prevGroups]);
        setSelectedGroup(newGroup);

        const updatedGroupDoc = await getDoc(doc(db, 'groups', newGroupId));
        if (updatedGroupDoc.exists()) {
          const updatedGroupData = updatedGroupDoc.data();
          await fetchUserNames(updatedGroupData);
        }
      }
    } catch (error) {
      console.error('Error refreshing groups:', error);
    }
  };

  const handleGroupModalSubmit = async (groupName, items) => {
    if (!authUser?.uid) {
      console.error('No user ID available');
      return;
    }

    try {
      const groupRef = await addDoc(collection(db, 'groups'), {
        name: groupName,
        name_lower: groupName.toLowerCase(),
        items: items.map(item => ({
          name: item.name,
          id: item.id.toString()
        })),
        users: [{
          user: doc(db, 'users', authUser.uid),
          values: []
        }],
        created_at: serverTimestamp()
      });

      trackEvent('group_created', {
        user_id: authUser.uid,
        group_id: groupRef.id,
        group_name: groupName,
        items_count: items.length
      });

      trackEvent('group_joined', {
        user_id: authUser.uid,
        group_id: groupRef.id
      });

      setShowGroupModal(false);
      handleGroupAdded(groupRef.id);
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const renderGroupContent = () => {
    if (isLoadingGroups || isLoadingUser) {
      return null;
    }

    if (groups.length === 0) {
      return (
        <div className="no-groups-message">
          <p>You're not in any groups yet! Click <button 
            className="here-link" 
            onClick={() => setShowGroupModal(true)}
          >here</button> to create or join a group.</p>
        </div>
      );
    }

    return (
      <>
        <div className="group-header">
          <Select
            value={selectedGroup}
            onChange={handleGroupChange}
            options={groups}
            placeholder="Select a group..."
            isClearable={false}
            className="group-select"
            classNamePrefix="select"
            isDisabled={groups.length === 0}
          />
          <button 
            className="group-add-button"
            onClick={() => setShowGroupModal(true)}
          >
            +
          </button>
        </div>
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 'track' ? 'active' : ''}`}
            onClick={() => setActiveTab('track')}
          >
            Track
          </button>
          <button 
            className={`tab ${activeTab === 'feed' ? 'active' : ''}`}
            onClick={() => setActiveTab('feed')}
          >
            Feed
          </button>
        </div>
        {activeTab === 'track' ? (
          <>
            {groupChartData && (
              <BarChart 
                data={groupChartData}
                currentUserId={authUser?.uid}
              />
            )}
            {selectedGroup && (
              <div className="group-content">
                {renderUserSummary()}
              </div>
            )}
            {selectedGroup && (
              <div className="controls-container">
                {renderInputControls()}
              </div>
            )}
          </>
        ) : (
          <Feed selectedGroup={selectedGroup} />
        )}
      </>
    );
  };

  const getUserInitials = (firstName, lastName) => {
    if (!firstName) return '';
    
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName ? lastName.charAt(0) : '';
    
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const handleShouldRefreshGroups = (shouldRefresh) => {
    if (shouldRefresh) {
      setShouldRefreshGroups(true);
    }
  };

  const renderUserSummary = () => {
    if (!selectedGroup || !authUser) return null;

    const currentUserData = selectedGroup.users.find(u => 
      (u.user?.id === authUser?.id) || (u.user === authUser?.id)
    );

    if (!currentUserData || !currentUserData.values) return null;

    return (
      <div className="user-summary">
        <h3>Tally:</h3>
        <div className="values-container">
          {selectedGroup.items.map((item, index) => {
            const valueObj = currentUserData.values.find(v => {
              const valueItemId = v.item?.id || v.item;
              const itemId = item.id || item.ref?.id;
              return valueItemId === itemId;
            });

            return (
              <div 
                key={`summary-${item.id || item.ref?.id || index}`} 
                className="value-item"
              >
                <span className="item-name">{item.name}:</span>
                <span className="item-value">{valueObj?.value || 0}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (authUser && authUser) {
      const isNewUser = authUser.metadata.creationTime === authUser.metadata.lastSignInTime;
      if (isNewUser) {
        trackEvent('user_signup', {
          user_id: authUser.uid,
          signup_method: authUser.providerData[0]?.providerId
        });
      }
    }
  }, [authUser]);

  const isLoading = () => {
    const isChartLoading = selectedGroup && !groupChartData;
    return isLoadingUser || 
           isLoadingGroups || 
           isUpdating || 
           isChartLoading;
  };

  const renderUserSection = () => {
    if (!authUser) return null;

    const displayName = authUser.displayName || authUser.first_name || 'New User';
    const firstName = displayName.split(" ")[0];

    return (
      <div 
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "end",
          cursor: "pointer"
        }}
        onClick={() => navigate('/profile')}
      >
        {authUser?.photo ? (
          <div className="header-photo-container">
            <img 
              alt="user avatar" 
              className="headerImg" 
              src={authUser.photo} 
              style={{ marginLeft: "10px"}} 
              onError={(e) => {
                e.target.style.display = 'none';
                const container = e.target.closest('.header-photo-container');
                if (container) {
                  const initialsDiv = document.createElement('div');
                  initialsDiv.className = 'headerInitials';
                  initialsDiv.textContent = getUserInitials(displayName);
                  container.appendChild(initialsDiv);
                }
              }}
            />
          </div>
        ) : (
          <div className="headerInitials">
            {getUserInitials(displayName)}
          </div>
        )}
        <span className="header">{firstName}</span>
      </div>
    );
  };

  const renderInputControls = () => {
    if (!selectedGroup) return null;

    // If group is not active, show "Closed" message
    if (selectedGroup.active === false) {
      return (
        <div className="closed-group-message">
          <span>Closed</span>
        </div>
      );
    }

    // If group is active, show the input controls
    return (
      <div className="input-controls">
        <Select
          value={selectedItem}
          onChange={setSelectedItem}
          options={selectedGroup.items?.map(item => ({
            value: item.id || item.ref?.id,
            label: item.name,
            ...item
          }))}
          placeholder="Select an item..."
          className="item-select"
          classNamePrefix="select"
          isSearchable={false}
          menuPlacement="auto"
          menuPosition="absolute"
          openMenuOnClick={true}
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            control: (base) => ({
              ...base,
              cursor: 'pointer',
              backgroundColor: 'var(--background-color)',
              borderColor: 'rgba(255, 255, 255, 0.2)',
              '&:hover': {
                borderColor: 'rgba(255, 255, 255, 0.3)'
              }
            }),
            option: (base) => ({
              ...base,
              cursor: 'pointer',
              backgroundColor: 'var(--background-color)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }),
            menuList: (base) => ({
              ...base,
              backgroundColor: 'var(--background-color)',
              borderRadius: '4px',
              border: '1px solid rgba(255, 255, 255, 0.2)'
            })
          }}
          components={{
            IndicatorSeparator: () => null
          }}
        />
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="number-input"
          placeholder="1"
        />
        <button
          onClick={() => handleInputUpdate(selectedItem?.value, value)}
          className="add-button"
          disabled={!value || !selectedItem || isLoading()}
        >
          +
        </button>
      </div>
    );
  };

  return (
    <LoadingWrapper loading={isLoadingUser || isLoadingGroups}>
      <div className="home-container">
        <div className="logout">
          {renderUserSection()}
        </div>
        <div className="group-chart-container">
          {renderGroupContent()}
        </div>
        <GroupModal 
          isOpen={showGroupModal}
          onClose={() => setShowGroupModal(false)}
          onSubmit={handleGroupModalSubmit}
          handleShouldRefreshGroups={handleShouldRefreshGroups}
        />
      </div>
    </LoadingWrapper>
  );
}

export default Home;
